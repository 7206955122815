



































import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator'
import {DappDailyCache} from '@/model/resource/DappDailyCache'
import {InputDappDailyCacheSchema} from '@/schema/resource/DappDailyCache/InputDappDailyCacheSchema'

@Component
export default class PersistDappDailyCacheView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputDappDailyCacheSchema()
  dappDailyCache = new DappDailyCache()

  async created() {
    this.schema.collectionDapp.hasContract = true

    this.populateResource()
    await this.populate()
  }

  populateResource() {
    this.schema.collectionDapp.queryAsPage()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.dappDailyCache.getDappDailyCache(id)
    }

    this.$await.done('getDappDailyCache')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.dappDailyCache.persistDappDailyCache()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/dapp-daily-cache/list')
  }
}
